<template>
    <div class="mx-error__page">
      <h1>404</h1>
      <h2></h2>
      <router-link to='/'>to home</router-link>
    </div>
</template>

<script>
    export default {
        name: "notFound"
    }
</script>

<style lang="scss" scoped>

  .mx-error__page {
    text-align: center;
  }

  h1 {
    font-weight: 300;
    color: darkred;
    font-size: 60px;
  }
  h2   {
    font-weight: 300;
    margin-bottom: 30px;
    display: block;
  }
  a {
    border: 1px solid #2a73bb;
    padding: 10px 30px;
    border-radius: 100px;
    color: #2a73bb;
    margin-top: 30px;
    transition: all 250ms ease;
    &:hover {
      background-color: #2a73bb;
      color: #ffffff;
    }
  }

</style>
